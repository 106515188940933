import React, { useState, useEffect } from "react";

function InitialForm({ formData, handleChange, handleGoalChange, handleServiceChange, goToNextStep }) {
    const [isFormValid, setIsFormValid] = useState(false);

    useEffect(() => {
        const checkFormValidity = () => {
            // Check if all required inputs are filled
            const isInfoComplete = (formData.email.trim() !== '' && /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(formData.email.trim())) &&
                formData.companyName.trim() !== '' &&
                formData.contactPerson.trim() !== '' &&
                (formData.phone.trim() !== '' && /^(\+\d{1,2}\s?)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/.test(formData.phone.trim())) &&
                formData.industry.trim() !== '';

            // Check if at least one goal is selected
            const isAtLeastOneGoalSelected = formData.goals.length > 0;

            setIsFormValid(isInfoComplete && isAtLeastOneGoalSelected);
        };



        checkFormValidity();
    }, [formData]);


    return (
        <div className="client-form">
            <h2>General Information</h2>
            <p><span style={{
                color: "lightcoral",
                fontWeight: 'bold'
            }}>FYI:</span> Use your laptop to fill this out for best experience since there is going to be a lot of typing.</p>
            <div className="form-group">
                <input
                    type="email"
                    id="email"
                    name="email"
                    required
                    placeholder=" "
                    value={formData.email}
                    onChange={(e) => handleChange('email', e.target.value)}
                />
                <label htmlFor="email">Email Address:<span className="required">*</span></label>
            </div>
            <div className="form-group">
                <input
                    type="text"
                    required
                    placeholder=" "
                    value={formData.companyName}
                    onChange={(e) => handleChange('companyName', e.target.value)}
                />
                <label>Company Name:<span className="required">*</span></label>
            </div>
            <div className="form-group">
                <input
                    type="text"
                    required
                    placeholder=" "
                    value={formData.contactPerson}
                    onChange={(e) => handleChange('contactPerson', e.target.value)}
                />
                <label>Contact Person:<span className="required">*</span></label>
            </div>
            <div className="form-group">
                <input
                    type="tel"
                    required
                    placeholder=" "
                    value={formData.phone}
                    onChange={(e) => handleChange('phone', e.target.value)}
                />
                <label>Phone Number:<span className="required">*</span></label>
            </div>
            <div className="form-group">
                <input
                    type="text"
                    required
                    placeholder=" "
                    value={formData.industry}
                    onChange={(e) => handleChange('industry', e.target.value)}
                />
                <label>Industry:<span className="required">*</span></label>
            </div>
            <div className="form-group">
                <input
                    type="url"
                    placeholder=" "
                    value={formData.website}
                    onChange={(e) => handleChange('website', e.target.value)}
                />
                <label>Website (optional):</label>
            </div>

            <h3>Goals<span className="required">*</span></h3>
            <div className="checkbox-group">
                {['Increase Sales', 'Rebranding, Remodeling, Rebuilding', 'Increase engagement and interaction', 'More press / PR', 'Increase guest loyalty and frequency', 'Other: We will discss with you...'].map((goal, idx) => (
                    <div key={idx} className="checkbox-item">
                        <input
                            type="checkbox"
                            className="sr-only"
                            id={`goal-${idx}`}
                            checked={formData.goals.includes(goal)}
                            onChange={(e) => handleGoalChange(goal, e.target.checked)}
                        />
                        <label className="checkbox-label" htmlFor={`goal-${idx}`}>
                            <span className="checkbox-custom"></span>
                            {goal}
                        </label>
                    </div>
                ))}
            </div>
            <div className="button-container">
                <button className="next-btn" onClick={goToNextStep} disabled={!isFormValid}>→</button>
            </div>
        </div>
    );
}

export default InitialForm;
