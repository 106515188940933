function PaidAdForm({ formData, handleChange }) {
    const handleInput = (field, value) => {
        handleChange(field, value, 'paidAd');
    };

    return (
        <div className="client-form">
            <h2>Paid Advertising Specific Questions<span className="required">*</span></h2>
            <div className="form-group">
                <input
                    type="text"
                    required
                    placeholder=" "
                    value={formData.paidAd.objectives}
                    onChange={(e) => handleInput('objectives', e.target.value)}
                />
                <label>Advertising Goals:</label>
            </div>
            <div className="form-group">
                <input
                    type="text"
                    required
                    placeholder=" "
                    value={formData.paidAd.audienceSegments}
                    onChange={(e) => handleInput('audienceSegments', e.target.value)}
                />
                <label>Key Audience Segments to Target:</label>
            </div>
            <div className="form-group">
                <input
                    type="text"
                    required
                    placeholder=" "
                    value={formData.paidAd.trends}
                    onChange={(e) => handleInput('trends', e.target.value)}
                />
                <label>Industry Trends and Insights:</label>
            </div>
            <div className="form-group">
                <input
                    type="text"
                    required
                    placeholder=" "
                    value={formData.paidAd.platforms}
                    onChange={(e) => handleInput('platforms', e.target.value)}
                />
                <label>Preferred Advertising Platforms:</label>
            </div>
        </div>
    );
}

export default PaidAdForm;