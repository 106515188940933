// src/Team.js

import React from 'react';
import '../css/Team.css';

function Team() {
    return (
        <section id="team-section">
            <div className="team-intro">
                <h2>Meet the Quantum Team</h2>
                <p>At the helm of our innovative journey is Marcus Chau, a visionary with a rich background in Computer Science, Mathematics, Business Studies at New York University.</p>
                <p>We've cultivated a dynamic company culture, merging robust technical expertise with cutting-edge marketing strategies to redefine the digital landscape. Our team comprises handpicked professionals who not only meet but exceed the industry standards. Each member brings a unique fusion of intense technical skills and a deep understanding of marketing psychology, honed through real-world experience.</p>
                <a href="#appointment" className="cta-button">
                    <span className="cta-main">Speak to Our Team Today</span>
                    <span className="cta-sub">Schedule Your Free Audit Call Today</span>
                </a>
            </div>
            <div className="team-photos">
                <div className="member">
                    <div className="member-image techy-border">
                        <img src={require('../images/Marcus.JPG')} alt="Founder" />
                    </div>
                    <h3>Marcus Chau</h3>
                    <p>FOUNDER</p>
                </div>
                <div className="member">
                    <div className="member-image techy-border">
                        <img src={require('../images/Dylan.jpeg')} alt="" />
                    </div>
                    <h3>Dylan Kim</h3>
                    <p>CHIEF PROJECT OFFICER</p>
                </div>
                <div className="member">
                    <div className="member-image techy-border">
                        <img src={require('../images/Stan.png')} alt="Innovator 3" />
                    </div>
                    <h3>Stan Zhou</h3>
                    <p>CHIEF MARKETING OFFICER</p>
                </div>
            </div>
        </section>
    );
}

export default Team;
