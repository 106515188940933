// src/App.js

import React, { useState, useEffect, useRef } from 'react';
import { HashRouter, Router, Route, Routes } from 'react-router-dom';
import './App.css';
import Navbar from './App/Navbar';
import Home from './App/Pages/Home';
import About from './App/Pages/About';
import Team from './App/Pages/Team';
import AppointmentForm from './App/Pages/AppointmentForm';
import Trust from './App/Pages/Trust';
import Footer from './App/Pages/Footer';
import Offer from './App/Pages/Offer';
import ClientForm from './App/Pages/ClientForm';
import SuccessPage from './App/Pages/clientslides/SuccessPage';

function App() {
  return (
    <HashRouter>
      <Routes>
        <Route path="/" element={<SPA />} />
        <Route path="/client-pre-kickoff-form" element={<ClientForm />} />
        <Route path="/success" element={<SuccessPage />} />
      </Routes>
    </HashRouter>
  );
}

function SPA() {
  const [buttonYPosition, setButtonYPosition] = useState(7); // Initial position in vh
  const maxMovement = 69; // Adjust this based on how far you want the button to move

  const [isInAppointmentZone, setIsInAppointmentZone] = useState(false);
  const [isScrolling, setIsScrolling] = useState(false);
  const scrollTimeoutRef = useRef(null);

  const handleScroll = () => {
    const scrollY = window.scrollY;
    const windowHeight = window.innerHeight;
    // Calculate the new Y position of the button
    let newYPosition = Math.min(maxMovement, 7 + scrollY / windowHeight * 100);
    setButtonYPosition(newYPosition);

    // Determine if the current scroll position is within the AppointmentForm zone
    const appointmentForm = document.querySelector('#appointment');
    if (appointmentForm) {
      const formPosition = appointmentForm.getBoundingClientRect();
      const formTop = formPosition.top + window.scrollY;
      const formBottom = formPosition.bottom + window.scrollY;
      const isInZone = scrollY >= formTop && scrollY <= formBottom;
      setIsInAppointmentZone(isInZone);
    }

    // User is scrolling, hence set isScrolling to true and clear any existing timeout
    setIsScrolling(true);
    clearTimeout(scrollTimeoutRef.current);

    // Set up the logic to hide the button after 1 second if in the appointment zone
    scrollTimeoutRef.current = setTimeout(() => {
      if (isInAppointmentZone) {
        setIsScrolling(false);
      }
    }, 1000);
  };

  // Attach the scroll event listener
  useEffect(() => {
    window.addEventListener('scroll', handleScroll, { passive: true });

    return () => {
      window.removeEventListener('scroll', handleScroll);
      // Clear the timeout when the component unmounts
      clearTimeout(scrollTimeoutRef.current);
    };
  }, [maxMovement]);

  return (
    <div className="App">
      <Navbar />
      <Home />
      <About />
      <Trust />
      <Team />
      <AppointmentForm />
      <Footer />
    </div>
  );
}

export default App;
