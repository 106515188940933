import React from 'react';
import '../css/Home.css';  // Assuming you have a CSS file for styling

function Home() {
    return (
        <section id="home" className="apple-home">
            <div className="content-wrapper">
                <div className="image-content">
                    <img src={require('../images/frontImg.png')} alt="Innovative Marketing" />
                </div>

                <div className="text-content">
                    <h1>Dare To Dominate.</h1>
                    <p style={{fontWeight: 'bold'}}>Empowering your brand's ascent with expert-led ads, SEO mastery, and web innovation - your success story, brilliantly coded from <span style={{color: 'var(--accent)'}}>concept to culmination.</span></p>
                    <p style={{fontSize: '1.3em'}}>Stop wasting time and money. It's time to make your budget count, scale your business and blow up your sales.</p>
                    {/* <a href="#appointment" className="cta-button">
                        <span className="cta-main">Speak to Our Team Today</span>
                        <span className="cta-sub">Schedule Your Free Audit Call Today</span>
                    </a> */}
                </div>
            </div>
        </section>
    );
}

export default Home;