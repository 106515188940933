import React from 'react';
import { useNavigate } from 'react-router-dom';

const SuccessPage = () => {
    const navigate = useNavigate();

    // Define your styles
    const pageStyle = {
        textAlign: 'center',
        marginTop: '50px'
    };

    const headerStyle = {
        color: 'green',
        fontSize: '24px'
    };

    const textStyle = {
        color: 'grey',
        fontSize: '18px'
    };

    return (
        <div style={pageStyle}>
            <h1 style={headerStyle}>Submission Successful</h1>
            <p style={textStyle}>Your information has been successfully submitted. We will be in touch soon!</p>
            <p style={textStyle}>You can now close this tab!</p>
        </div>
    );
}

export default SuccessPage;
