function WebDesignForm({ formData, handleChange }) {
    const handleInput = (field, value) => {
        handleChange(field, value, 'webDesign');
    };
    
    return (
        <div className="client-form">
            <h2>Web Design Specific Questions<span className="required">*</span></h2>
            <div className="form-group">
                <input
                    type="text"
                    required
                    placeholder=" "
                    value={formData.webDesign.mainGoals}
                    onChange={(e) => handleInput('mainGoals', e.target.value)}
                />
                <label>Main Goals for the Website:</label>
            </div>
            <div className="form-group">
                <input
                    type="text"
                    required
                    placeholder=" "
                    value={formData.webDesign.features}
                    onChange={(e) => handleInput('features', e.target.value)}
                />
                <label>Features and Functionalities Desired:</label>
            </div>
            <div className="form-group">
                <input
                    type="text"
                    required
                    placeholder=" "
                    value={formData.webDesign.audience}
                    onChange={(e) => handleInput('audience', e.target.value)}
                />
                <label>Target Audience and Demographics:</label>
            </div>
            <div className="form-group">
                <input
                    type="text"
                    required
                    placeholder=" "
                    value={formData.webDesign.designElements}
                    onChange={(e) => handleInput('designElements', e.target.value)}
                />
                <label>Design Elements and Brand Guidelines:</label>
            </div>
        </div>
    );
}

export default WebDesignForm;