import React, { useState, useEffect } from 'react';
import InitialForm from './clientslides/InitialForm';
import ServiceSelectionForm from '../components/ServiceSelectionForm';
import SEOForm from './clientslides/SeoForm';
import WebDesignForm from './clientslides/WebdesignForm';
import PaidAdForm from './clientslides/PaidAdForm';
import '../css/ClientForm.css';
import { addBusinessData } from '../../Api/api';
import emailjs from 'emailjs-com';
import { useNavigate } from 'react-router-dom';


function MainFormContainer() {
    const navigate = useNavigate();
    const [currentStep, setCurrentStep] = useState(0);
    const [formData, setFormData] = useState({
        email: '',
        companyName: '',
        contactPerson: '',
        phone: '',
        industry: '',
        website: '',
        goals: [],
        selectedServices: [],
        isUnsure: false,
        seo: {
            targets: '',
            performance: '',
            keywordResearch: '',
            strategies: ''
        },
        webDesign: {
            mainGoals: '',
            features: '',
            audience: '',
            designElements: ''
        },
        paidAd: {
            objectives: '',
            audienceSegments: '',
            trends: '',
            platforms: ''
        }
    });

    const handleServiceChange = (serviceId, isUnsureSelection = false) => {
        if (isUnsureSelection) {
            setFormData(prev => ({
                ...prev,
                selectedServices: [],
                isUnsure: !prev.isUnsure
            }));
        } else {
            setFormData(prev => ({
                ...prev,
                selectedServices: prev.selectedServices.includes(serviceId)
                    ? prev.selectedServices.filter(id => id !== serviceId)
                    : [...prev.selectedServices, serviceId],
                isUnsure: false
            }));
        }
    };

    const handleChange = (field, value, nested = null) => {
        setFormData(prev => ({
            ...prev,
            [nested ? nested : field]: nested ? { ...prev[nested], [field]: value } : value
        }));
    };

    const handleGoalChange = (goal, checked) => {
        setFormData(prev => ({
            ...prev,
            goals: checked
                ? [...prev.goals, goal]
                : prev.goals.filter(g => g !== goal)
        }));
    };

    const goToNextStep = () => {
        if (currentStep < 4) { // Assuming there are 4 steps
            setCurrentStep(currentStep + 1);
        }
    };

    const goToPreviousStep = () => {
        if (currentStep > 0) {
            setCurrentStep(currentStep - 1);
        }
    };

    const sendEmail = (formData) => {
        // Initialize templateParams with required and always present fields
        const templateParams = {
            to_email: formData.email,
            to_name: formData.contactPerson,
            company_name: formData.companyName,
            phone: formData.phone,
            industry: formData.industry,
        };
    
        // Sending email using EmailJS
        return emailjs.send('service_1i5s9bq', 'template_bh3f7g8', templateParams, 'wYh5oyTqcyxNeAzY_');
    }
    

    const handleSubmit = async () => {
        try {
            console.log(formData);
            await addBusinessData(formData);
            await sendEmail(formData);
            alert("Data and email successfully submitted!");
            // Optionally reset form or redirect user
            navigate('/success');

        } catch (error) {
            console.error("Submission failed: ", error);
            alert("Failed to submit data.");
        }
    };

    const renderStep = () => {
        switch (currentStep) {
            case 0:
                return <InitialForm formData={formData} handleChange={handleChange} handleGoalChange={handleGoalChange} goToNextStep={goToNextStep} />;
            case 1:
                return <ServiceSelectionForm selectedServices={formData.selectedServices} isUnsure={formData.isUnsure} handleServiceChange={handleServiceChange} goToNextStep={goToNextStep} goToPreviousStep={goToPreviousStep} />;
            case 2:
                return formData.selectedServices.map((service, index) => {
                    switch (service) {
                        case 'seo':
                            return <SEOForm key={index} formData={formData} handleChange={handleChange} />;
                        case 'web-design-dev':
                            return <WebDesignForm key={index} formData={formData} handleChange={handleChange} />;
                        case 'paid-advertising':
                            return <PaidAdForm key={index} formData={formData} handleChange={handleChange} />;
                        default:
                            return null;
                    }
                });
            default:
                return (
                    <div>
                        <h2>Review and Submit</h2>
                        <button onClick={() => console.log(formData)}>Submit</button>
                    </div>
                );
        }
    };

    return (
        <div className='client-form-container'>
            {renderStep()}
            <div className='client-form'>
                {currentStep > 1 && (
                    <div className='button-container'>
                        <button className='back-btn' onClick={goToPreviousStep}>←</button>
                        <button className='submit-btn' onClick={handleSubmit}>Submit</button>
                    </div>
                )}
            </div>
        </div>
    );
}

export default MainFormContainer;