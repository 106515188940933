import React from 'react';
import '../css/Appointment.css';
import { FaCheckCircle } from 'react-icons/fa'; // Importing the icon

function AppointmentForm() {
    return (
        <section id="appointment">
            <div className="calendly-embed">
                <iframe 
                    src="https://calendly.com/agilenetics/15-min-introductory-call"
                    width="100%" 
                    height="930" 
                    scrolling="no" 
                    frameBorder="0">
                </iframe>
            </div>
            <div className="appointment-info">
                <h2>BOOK YOUR SESSION NOW</h2>
                <p><em>Complimentary 15-Minute Discovery Call</em></p>
                <p>Wrap up your Discovery Session with a comprehensive blueprint tailored to your brand's needs, pinpointing the strategic initiatives you need to prioritize. You'll gain insights into the power of SEO, innovative web solutions, alongside Funnels and Targeted Advertising.</p>
                <p>Book a time on our calendar for a one-on-one consultation and let's embark on a journey to sculpt your business's digital future. We're excited about the opportunity to guide you to your next big breakthrough!</p>
                <h3>THIS DISCOVERY SESSION IS IDEAL FOR:</h3>
                <ul>
                    <li><FaCheckCircle className="bullet-icon" /> <div>Companies ready to elevate their website to a <span className="bold-text">dynamic, high-performance marketing platform.</span></div></li>
                    <li><FaCheckCircle className="bullet-icon" /> <div>Enterprises ready to harness the power of digital innovation for a <span className="bold-text">seamless transition from traditional to online markets.</span></div></li>
                    <li><FaCheckCircle className="bullet-icon" /> <div>Pioneers aiming to <span className="bold-text">escalate their profits by unlocking the potential of comprehensive digital marketing intelligence.</span></div></li>
                    <li><FaCheckCircle className="bullet-icon" /> <div>Innovators seeking to <span className="bold-text">infuse their social media campaigns with cutting-edge technology.</span></div></li>
                    <li><FaCheckCircle className="bullet-icon" /> <div>Brands in search of a <span className="bold-text">dedicated ally, committed to achieving shared triumphs in the digital arena.</span></div></li>
                </ul>
            </div>
        </section>
    );
}

export default AppointmentForm;
