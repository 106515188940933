import React from 'react';

function ServiceSelectionForm({ selectedServices, isUnsure, handleServiceChange, goToNextStep, goToPreviousStep }) {
    const services = [
        { id: 'seo', label: 'SEO' },
        { id: 'web-design-dev', label: 'Web Design and Dev' },
        { id: 'paid-advertising', label: 'Paid Advertising' }
    ];

    const isNextEnabled = selectedServices.length > 0 || isUnsure;

    return (
        <div className='client-form'>
            <h2>Select All Services You're Interested In:<span className='required'>*</span></h2>
            {services.map((service) => (
                <div key={service.id} className="choice">
                    <input
                        type="checkbox"
                        id={service.id}
                        name="service"
                        value={service.label}
                        onChange={() => handleServiceChange(service.id)}
                        checked={selectedServices.includes(service.id)}
                        disabled={isUnsure}
                    />
                    <label htmlFor={service.id}>{service.label}</label>
                </div>
            ))}
            <div className="choice">
                <input
                    type="checkbox"
                    id="unsure-discuss"
                    name="unsure-discuss"
                    onChange={() => handleServiceChange('unsure-discuss', true)}
                    checked={isUnsure}
                    disabled={selectedServices.length > 0}
                />
                <label htmlFor="unsure-discuss">Unsure/Discuss with Us</label>
            </div>
            
            { isUnsure ? (
                <div className='button-container'>
                    <button className='back-btn' onClick={goToPreviousStep}>←</button>
                    <button type='submit' className='submit-btn'>Submit</button>
                </div>
            ): (
                <div className='button-container'>
                    <button className='back-btn' onClick={goToPreviousStep}>←</button>
                    <button className='next-btn' onClick={goToNextStep} disabled={!isNextEnabled}>→</button>
                </div>
            )}
        </div>
    );
}

export default ServiceSelectionForm;
