function SEOForm({ formData, handleChange }) {
    const handleInput = (field, value) => {
        handleChange(field, value, 'seo');
    };

    return (
        <div className="client-form">
            <h2>SEO Specific Questions<span className="required">*</span></h2>
            <div className="form-group">
                <input
                    type="text"
                    required
                    placeholder=" "
                    value={formData.seo.targets} 
                    onChange={(e) => handleInput('targets', e.target.value)}
                />
                <label>SEO Targets:</label>
            </div>
            <div className="form-group">
                <input
                    type="text"
                    required
                    placeholder=" "
                    value={formData.seo.perfomance}
                    onChange={(e) => handleInput('performance', e.target.value)}
                />
                <label>Website Performance in Search Rankings:</label>
            </div>
            <div className="form-group">
                <input
                    type="text"
                    required
                    placeholder=" "
                    value={formData.seo.keywordResearch}
                    onChange={(e) => handleInput('keywordResearch', e.target.value)}
                />
                <label>Keyword Research Done Previously:</label>
            </div>
            <div className="form-group">
                <input
                    type="text"
                    required
                    placeholder=" "
                    value={formData.seo.strategies}
                    onChange={(e) => handleInput('strategies', e.target.value)}
                />
                 <label>Past SEO Strategies Implemented:</label>
            </div>
        </div>
    );
}

export default SEOForm;