// src/Navbar.js

import React from 'react';
import { Link } from 'react-router-dom';

function Navbar() {
    return ( 
        <nav className="apple-navbar">
            <div className="logo">
                <a href="/"><img src={require('./images/logo.png')} style={{
                    
                    width: '150px'
                }} alt="AGNET" /></a>
            </div>
            <div className="nav-links">
                <a href="#appointment" className="cta">Free Audit Call</a>
                {/* <a href="#" className="cta">Contact Us</a> */}
            </div>
        </nav>
    );
}

export default Navbar;
