// src/Team.js

import React from 'react';
import '../css/Trust.css';

function Trust() {
    return (
        <section id="trusted-companies" className="trusted-companies">
            <h2>Companies That Trust Us</h2>
            <div className="companies-container">
                <div className="company">
                    <img src={require('../images/AlBuddyLogo.png')} alt="AlBuddy Logo" className="company-logo" />
                </div>
                {/* <div className="company">
                    <img src={require('../images/Stooped.png')} alt="Stooped Logo" className="company-logo" />
                    <p className="company-name">Stooped</p>
                </div> */}
                {/* <!-- You can easily add more companies by copying and pasting the "company" div and updating the image and name --> */}
            </div>
        </section>

    );
}

export default Trust;
