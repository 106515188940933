import { initializeApp } from 'firebase/app';
import { getFirestore, addDoc, collection } from 'firebase/firestore';
import { getAnalytics, isSupported } from 'firebase/analytics';

// Your web app's Firebase configuration
const firebaseConfig = {
  // apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  // authDomain: process.env.REACT_APP_FIREBASE_AUTHDOMAIN,
  // projectId: process.env.REACT_APP_FIREBASE_PROJECTID,
  // storageBucket: process.env.REACT_APP_FIREBASE_STORAGEBUCKET,
  // messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGINGSENDERID,
  // appId: process.env.REACT_APP_FIREBASE_APPID,
  // measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENTID
  apiKey: "AIzaSyCdPDvp-b-7rG7GvMte07kwFnvNX8pvIxg",
  authDomain: "agilenetics-eef7c.firebaseapp.com",
  projectId: "agilenetics-eef7c",
  storageBucket: "agilenetics-eef7c.appspot.com",
  messagingSenderId: "914973180938",
  appId: "1:914973180938:web:a721abe1a5c708da348bf3",
  measurementId: "G-6MV0NEKXBD"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);


console.log('Firebase API Key:', process.env.REACT_APP_FIREBASE_API_KEY); 
console.log(process.env.REACT_APP_FIREBASE_API_KEY); // Check if the API key is logging correctly


// Enable analytics if it's supported
isSupported().then((isSupported) => {
  if (isSupported) {
    getAnalytics(app);
  }
});

const addBusinessData = async (formData) => {
  try {
    const businessData = {
      email: formData.email,
      companyName: formData.companyName,
      contactPerson: formData.contactPerson,
      phone: formData.phone,
      industry: formData.industry,
      website: formData.website,
      goals: formData.goals,
      selectedServices: formData.selectedServices,
      isUnsure: formData.isUnsure,
      seoQuestions: formData.seo, // Directly use the seo object
      webDesignQuestions: formData.webDesign, // Directly use the webDesign object
      advertisingQuestions: formData.paidAd
    };

    const docRef = await addDoc(collection(db, 'businesses'), businessData);
    console.log('Document successfully written!', docRef.id);
    return docRef.id; // Optionally return the document ID
  } catch (error) {
    console.error("Error writing document: ", error);
    throw new Error("Failed to save business data");
  }
};

  
export { addBusinessData };