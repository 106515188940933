// src/Footer.js

import React from 'react';
import '../css/Footer.css';

function Footer() {
    return (
        <footer id="footer-section">
            <div className="inquiry-section">
                <h3>Questions? Reach Out.</h3>
                <p>For general inquiries, connect with our team:</p>
                <a href="mailto:support@agnet-media.com">support@agnet-media.com</a>
            </div>

            <br />

            {/* <div className="call-section">
                <a href="#appointment" className="cta-button">
                    <span className="cta-main">Connect with Our Experts</span>
                    <span className="cta-sub">Book Your Complimentary Audit Call</span>
                </a>
            </div> */}

            <br />
            <br />
            
            {/* <div className="legal-section">
                <div className="legal-links">
                    <a href="/terms">Terms</a> | 
                    <a href="/privacy">Privacy</a> | 
                    <a href="/disclaimer">Disclaimer</a>
                </div>
            </div> */}
            <div className="legal-section" >
                AGNET &#169; 2024. 
            </div>
        </footer>
    );
}

export default Footer;
