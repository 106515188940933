// src/About.js
import React from 'react';
import '../css/About.css';

function About() {
    return (
        <section id="about" className="apple-about">
            <div className="philosophy-wrapper">
                <h3>Our Philosophy</h3>
                {/* <h4>Paid Advertising</h4> */}
                <p>
                AGNET Media is the edge your brand needs. <br /><br />

                No fluff, no clutter, just sharp web design, pinpoint SEO, and targeted ads that pack a punch. 
                We're not your typical agency. 
                We cut the red tape and turbo-charge your online presence. <br /><br />
                <span style={{fontWeight: 'bold', color: 'var(--text-primary)', opacity: 0.9}}>Our work? </span>Rocket fuel for your business growth. <br />
                <span style={{fontWeight: 'bold', color: 'var(--text-primary)', opacity: 0.9}}>Ready to launch?</span> Let's blast off. <br />
                <span style={{fontWeight: 'bold', color: 'var(--text-primary)', opacity: 0.9}}>Contact Us - </span> It's Time to Lead, Not Follow.
                </p>

                <div style={{ display: 'flex', justifyContent: 'center'}}>
                    <a href="#appointment" className="cta-button">
                        <span className="cta-main">Speak to Our Team Today</span>
                        <span className="cta-sub">Schedule Your Free Audit Call Today</span>
                    </a>
                </div>
            </div>
            
            {/* <div className="services">
                <div className="service">
                    <h3>Analytics & Insights</h3>
                    <p>Deep insights from custom analytics tools.</p>
                </div>
                <div className="service">
                    <h3>Automation</h3>
                    <p>Efficiency through bots and scripts.</p>
                </div>
                <div className="service">
                    <h3>Machine Learning</h3>
                    <p>Predictive analysis for trend forecasting.</p>
                </div>
                <div className="service">
                    <h3>Precise and Efficient</h3>
                    <p>Focused Excellence in Advertising.</p>
                </div>
            </div> */}
        </section>
    );
}

export default About;
